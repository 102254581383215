
@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-ExtraLight.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-Light.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-Regular.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-Bold.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-Black.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-BoldIt.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-BoldIt.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-BlackIt.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-BlackIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-ExtraLightIt.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-ExtraLightIt.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-LightIt.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-SemiboldIt.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-SemiboldIt.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-Semibold.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('./fonts/SourceSerifPro-It.woff2') format('woff2'),
        url('./fonts/SourceSerifPro-It.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}




html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  overflow: hidden;
  color: #716459;
}

.container {
  width: 100vw;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  position: relative;

  .loading-screen {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 1;
    transition: opacity 0.7s ease-in, visibility 0s linear 700ms;
    background-color: white;
    z-index: 999;

    &.fade-out {
      opacity: 0;
      visibility: hidden;
    }

    .gif-container {
      position: absolute;
      top: calc(50% - 62.5px);
      left: calc(50% - 62.5px);
      opacity: 0;
      transition: opacity 0.7s ease-in;

      img {
        width: 125px;
        height: 125px;
      }

      &.fade-in {
        opacity: 1;
      }

      &.fade-out {
        opacity: 0;
      }
    }

    .text-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.7s ease-in;
      transition-delay: 1s;

      h1 {
        font-family: 'Source Serif Pro';
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.7px;
      }

      &.fade-in {
        opacity: 1;
      }
    }
  }

  .content {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;

    @media screen and (max-width: 750px) {
      flex-direction: column;
      justify-content: space-between;
    }

    .drawer {
      width: 162px;
      overflow: hidden;
      background-color: #f1f1f1;
      border-right: 1px solid #716459;
      transition: width 0.3s ease-in-out;
      box-sizing: border-box;
      overflow-y: auto;

      @media screen and (max-width: 750px) {
        display: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      &:hover {
        width: 346px;  
      }

      &:first-of-type {
        &:hover {
          width: 447px;
        }
      }
      .about, .index {
        width: 447px;
        padding: 17px 20px;
        font-family: 'Source Serif Pro';
        font-size: 14px;
        line-height: 20px;
        box-sizing: border-box;

        h2 {
          font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        }

        a {
          color: #716459;
          text-decoration: none;
          border-bottom: 1px solid #716459;
          padding-bottom: 0px;

          &:hover {
             background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='rgb(113, 100, 89)' stroke-width='1' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
              background-position: bottom;
              background-repeat: repeat-x;
              background-size: 20px;
              border-bottom: 0;
              text-decoration: none;
          }
        }

        ul {
          margin-left: 40px;
          padding-left: 0;

        li {
          padding-left: 26px;
          text-indent: -29px;

          &::before {
            content: "→";
            padding-right: 20px;
          }

        }
      }
      }

      .index {
        overflow-y: auto;
        width: 346px;
        .project {
          color: rgba(#716459, 0.38);
          transition: color 0.3s ease-in-out;

          &.active {
            color: #716459;
          }

          &:hover {
            cursor: pointer;

            h2 {
              color: #716459;
              transition: color 0.3s ease-in-out;
            }
          }

          p {
            span {
              font-style: italic;
            }
          }
        }
        
      }
    }

    .img-container {
      width: calc(100vw - 324px);
      overflow: hidden;
      display: flex;
      transition: background-color 0.5s ease-in-out;
      will-change: background-color;
      position: relative;

      .controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;

        @media screen and (max-width: 750px) {
          display: flex;
        }

        .left, .right {
          width: 50%;
        }
      }

      #image {
        will-change: opacity;
        @media screen and (max-width: 750px) {
          display: none;
        }
      }

      #mobileImage {
        will-change: opacity;
        height: 100%;
        width: auto;
      }

      @media screen and (max-width: 750px) {
        height: 100%;
        width: auto;
        max-width: 100vw;
      }

      &.black {
        background-color: black;
        will-change: background-color;
        transition: background-color 0.5s ease-in-out;
      }

      video {
        margin: auto 0;
        transition: opacity 0.5s ease-in-out;
        will-change: opacity;
      }

      img {
        height:100%;
        width: auto;
        transform: translateX(-50%);
        margin-left: 50%;
        transition: opacity 0.5s ease-in-out;
        will-change: opacity;

        @media screen and (max-width: 750px) {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      .number {
        position: fixed;
        top: 17px;
        right: 20px;
        font-family: 'Source Serif Pro';
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.7px;

        @media screen and (max-width: 750px){
          display: none;
        }

        &.black {
          color: white;
        }
      }
    }
    .mobile {
      display: none;

      @media  screen and (max-width:750px){
        display: block;
      }
    }

    .mobile-drawer {
      width: 100%;
      height: 57px;
      overflow: hidden;
      background-color: white;
      position: relative;
      transition: all 0.5s ease-in-out;

      &.black {
        background-color: black;
        color: white;
      }

      &.open {
        height: calc(100%);
        height: calc(100vh);
        height: calc(100dvh);
        background-color: #E7EBEB;
        color: #716459;

        .index, .about {
          overflow-y: auto;
        }
      }

      

      .toggle {
        position: absolute;
        top: 15px;
        right: 27px;
        cursor: pointer;

        .button {
          cursor: pointer;
          font-family: 'Source Serif Pro';
          font-size: 14px;
          position: fixed;
          top: 25px;
          right: 27px;
        }
      }

      .about, .index {
        height: calc(100% - 57px);
        height: calc(100vh - 57px);
        height: calc(100dvh - 57px);
        padding: 25px 27px;
        font-family: 'Source Serif Pro';
        font-size: 14px;
        line-height: 20px;
        box-sizing: border-box;
        &::-webkit-scrollbar {
        display: none;
      }
        

        h2 {
          font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.7px;
        }

        a {
          color: #716459;
          text-decoration: none;
          border-bottom: 1px solid #716459;
          padding-bottom: 0px;

          &:hover {
             background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='rgb(113, 100, 89)' stroke-width='1' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
              background-position: bottom;
              background-repeat: repeat-x;
              background-size: 20px;
              border-bottom: 0;
              text-decoration: none;
          }
        }

        ul {
          margin-left: 40px;
          padding-left: 0;

        li {
          padding-left: 26px;
          text-indent: -29px;

          &::before {
            content: "→";
            padding-right: 20px;
          }


        }
      }
      }

      .index {
        .project {
          color: rgba(#716459, 0.38);
          transition: color 0.3s ease-in-out;

          &.active {
            color: #716459;
          }

          &:hover {
            cursor: pointer;

            h2 {
              color: #716459;
              transition: color 0.3s ease-in-out;
            }
          }

          p {
            span {
              font-style: italic;
            }
          }
        }
        
      }

    }

    .footer {
      display: none;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 28px;
      padding-bottom: 25px;
      gap: 5px;
      box-sizing: border-box;
      transition: all 0.5s ease-in-out;

      &.black {
        background-color: black;
        color: white;
      }

      svg {
        cursor: pointer;
      }

      .number {
        top: 17px;
        right: 20px;
        font-family: 'Source Serif Pro';
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.7px;
      }

      p {
        font-family: 'Source Serif Pro';
        font-size: 14px;
        line-height: 20px;
            span {
              font-style: normal;
            }
          }
      
      @media screen and (max-width: 750px) {
        display: flex;
      }
    }
  }
}
